import Vue from 'vue'
import { mapMutations } from 'vuex'

const getApi = () => import('@/services/api')
const getATApi = () => import('@/services/anonymousTexting')

/**
 * @typedef {{success: boolean, error: {message: string}, message: string}} Response
 */

/**
 * mixin
 */
export default {
  data: function () {
    return {
      error: null,
      submitting: false,
      loader: null
    }
  },
  methods: {
    ...mapMutations({
      setGlobalMessage: 'setMessage'
    }),
    /**
     * @param {function (import(@/services/api), import(@/services/anonymousTexting)): Promise<Response>} apiCall
     * @param {boolean} force
     * @param {boolean} emitOnSuccess
     * @param {function (Response): void} onFail
     * @param {function (Response): Promise<void|boolean>} onSuccess
     * @param {function (Error): void} onException
     * @returns {Promise<boolean>}
     */
    async $callApi (apiCall, {
      force,
      emitOnSuccess = true,
      onFail = this.$options.formFailed,
      onSuccess = this.$options.formSubmitted,
      onException = this.$options.formCrashed
    } = {}) {
      if (!force && !this.$el.checkValidity()) return
      this.submitting = true
      this.error = null
      try {
        this.showLoader()
        try {
          const [api, atApi] = await Promise.all([getApi(), getATApi()])
          const response = await apiCall(api, atApi)
          if (!response.success) {
            if (response.error && response.error.message) {
              this.error = response.error.message
            } else {
              this.error = response.message
            }
            if (onFail) {
              onFail.call(this, response)
            }
            return false
          } else {
            let emit = emitOnSuccess
            if (onSuccess) {
              const res = await onSuccess.call(this, response)
              if (typeof res === 'boolean') {
                emit = res
              }
            } else if (Vue.config.productionTip) {
              // console.warn(`TODO: handle success in ${this.$options.name}`)
              this.setGlobalMessage('')
            }
            if (emit) {
              this.$emit('success', response)
            }
            if (response && response.message) {
              this.setGlobalMessage(response)
            }
            return true
          }
        } catch (e) {
          if (e && e.status === 401) {
            this.$router.push({
              name: 'login',
              query: { redirect: this.$router.currentRoute.fullPath }
            })
            this.setGlobalMessage('You need to authorize again')
          }
          // console.warn(e)
          this.error = 'We are experiencing technical problems. Check your internet connection. Please try again later.'
          if (onException) {
            onException.call(this, e)
          }
          return false
        }
      } finally {
        this.submitting = false
        this.hideLoader()
      }
    },
    showLoader () {
      this.loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        color: '#f00',
        width: 125,
        height: 125
      })
    },
    hideLoader () {
      this.loader.hide()
    }
  }
}
