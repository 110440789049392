<template>
  <FormGroup
    class="o-form__input o-tooltip-container"
    :class="{
    'is-valid': showValidity && valid,
    'is-invalid': showValidity && !valid
  }">
    <OFormLabel :for="htmlId">
      <slot></slot>
    </OFormLabel>
    <OCRow class="input__group">
      <slot name="before"></slot>
      <!--suppress HtmlFormInputWithoutLabel -->
      <textarea ref="input" v-if="type === 'textarea'" v-bind="inputAttributes" v-on="inputListeners"></textarea>
      <!--suppress HtmlFormInputWithoutLabel -->
      <input ref="input" v-else v-bind="inputAttributes" v-on="inputListeners">
      <slot name="after"></slot>
    </OCRow>
    <o-tooltip v-if="hasTooltip">
      <slot name="tooltip"></slot>
    </o-tooltip>
  </FormGroup>
</template>

<script>
import htmlId from '../../mixins/htmlId'
import FormGroup from './FormGroup'
import OFormLabel from './OFormLabel'
import OCRow from '../containers/OCRow'

const OTooltip = () => import('../../objects/OTooltip')

export default {
  name: 'OFormInput',
  components: { OCRow, OFormLabel, OTooltip, FormGroup },
  inheritAttrs: false,
  mixins: [htmlId],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: String,
    name: String,
    type: String,
    value: {
      default: '',
      type: [String, Number]
    }
  },
  data: function () {
    return {
      dirty: false,
      internalValue: null,
      valid: false,
      showValidity: false
    }
  },
  computed: {
    hasTooltip () {
      return !!this.$slots.tooltip
    },
    inputAttributes () {
      let { inputClass = '', ...attributes } = this.$attrs
      // noinspection JSRedundantSwitchStatement
      switch (this.type) {
        // legacy - use type: email
        case 'username':
          attributes = {
            ...attributes,
            type: 'text',
            autocorrect: 'off',
            autocapitalize: 'none',
            autocomplete: 'username'
          }
          break
        case 'email':
          attributes = {
            ...attributes,
            autocorrect: 'off',
            autocapitalize: 'off',
            autocomplete: 'email',
            minlength: 3,
            inputmode: 'email'
          }
          break
      }
      return {
        type: this.type,

        ...attributes,

        ref: 'input',
        class: ['o-form__input__input', 'o-tooltip-trigger', `o-form__input__input--${this.type}`, inputClass],
        id: this.htmlId,
        name: this.name,
        value: this.value
      }
    },
    inputListeners () {
      return {
        ...this.$listeners,
        blur: this.onBlur,
        change: this.onChange,
        input: this.onInput
      }
    }
  },
  methods: {
    updateValidity () {
      this.valid = this.$refs.input && this.$refs.input.checkValidity()
    },
    onBlur (event) {
      this.$emit('blur')
      if (this.dirty) {
        this.showValidity = true
      }
      this.updateValidity()
    },
    onInput ({ target: { value } }) {
      this.$emit('input', value)
      this.dirty = true
      this.updateValidity()
      this.showValidity = true
    },
    onChange ({ target: { value } }) {
      this.$emit('change', value)
      this.updateValidity()
    },
    setCustomValidity (msg) {
      this.$refs.input.setCustomValidity(msg)
      this.updateValidity()
    },
    focus (options) {
      this.$refs.input.focus(options)
    }
  }
}
</script>

<style lang="scss">
.o-form__input__input {
  border: 1px solid darken($bg-color, 25%);
  @include typi('input');
  background: $bg-color;
  padding: vr(0.75);
  box-shadow: none;
  appearance: none;
  border-radius: 0;
  margin: 0;
  flex: 1;
  min-width: 0;

  &:focus {
    outline: 0;
  }

  &:disabled {
    background: $bg-disabled-color;
  }

  &::placeholder {
    @include typi('input-placeholder');
  }

  .is-valid & {
    border-color: #00aa00;
  }

  .is-invalid & {
    border-color: #ff0000
  }
}

.o-form__input__input--textarea {
  height: 4.5em;
  resize: vertical;
  font: inherit;
}

input:disabled {
  -webkit-opacity:1; /* Override iOS opacity change affecting text & background color */
  -webkit-text-fill-color:#545454; /* Override iOS / Android font color change */
}
</style>
